/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import DonationPower from "../components/pages/Home/DonationPower"
import DonationPowerMobile from "../components/pages/Home/DonationPower/mobile"

import { isMobile, isTablet } from "react-device-detect"

const BankAccount = ({ pageContext: translation }) => {
  return (
    <Layout translation={translation}>
      <SEO lang={translation.currentLang} title={translation.pageName} />

      {isMobile && isTablet ? (
        <DonationPowerMobile fontDefault={true} translation={translation} />
      ) : (
        <DonationPower fontDefault={true} translation={translation} />
      )}
    </Layout>
  )
}

export default BankAccount
