/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { Link } from "gatsby"

import GoBack from "../../../../assets/images/svg/go-back.svg"

import Cards from "./Cards"

const DonationPower = ({ translation, fontDefault }) => {
  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        padding: 0 20px 80px;
      `}
    >
      <header
        css={css`
          position: relative;
        `}
      >
        <Link
          href="/"
          css={css`
            position: absolute;
            left: 0;
          `}
        >
          <GoBack />
        </Link>

        <h2
          style={{
            color: "#203760",
            marginBottom: "43px",
            textAlign: "center",
          }}
        >
          Transferência Bancária
        </h2>
      </header>

      <p
        style={{
          color: "#203760",
          fontWeight: "600",
          fontSize: "16px",
          marginBottom: "20px",
        }}
      >
        Dados para transferência
      </p>
      <p
        style={{
          color: "#203760",
          fontSize: "16px",
          lineHeight: "22px",
          marginBottom: "50px",
        }}
      >
        IGREJA UNIVERSAL DO REINO DE DEUS
        <br /> CNPJ: 29.744.778 / 0001 - 97
      </p>

      <Cards translation={translation} />
      
    </section>
  )
}

export default DonationPower
